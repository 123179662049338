import { Injectable } from '@angular/core';
import {
  IOrderBy,
  IOrgaentity,
  ListResponse,
  Location,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

import { listLocations, listOrgaentities } from '@dep/frontend/appsync.queries';
import { AppsyncService } from '@dep/frontend/services/appsync.service';
import { UserService } from '@dep/frontend/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private locations?: ListResponse<Location>;
  private locationsSub$?: Subject<ListResponse<Location>>;
  private cacheProperties?: { userSub: string, date: Date };

  constructor(
    private logger: NGXLogger,
    private appsyncService: AppsyncService,
    private userService: UserService,
  ) { }

  public async getLocations(
    start?: number,
    limit?: number,
    search?: string,
    orderBy?: IOrderBy,
    filterDealershipId?: string,
  ): Promise<ListResponse<Location> | null> {
    const noParams = (
      start === undefined && limit === undefined && search === undefined
      && orderBy === undefined && filterDealershipId === undefined
    );
    if (
      (
        (
          this.cacheProperties !== undefined
          && this.cacheProperties.userSub === await this.userService.getCurrentSub()
          && this.cacheProperties.date.getTime() > new Date().getTime() - 300000
        )
        || (this.locationsSub$ !== undefined)
      )
      && noParams
    ) {
      if (this.locationsSub$ !== undefined) {
        // If request currently running, subscribe to response.
        return new Promise<ListResponse<Location>>((resolve, reject) => {
          this.logger.debug('Subscribing to locations response');
          this.locationsSub$?.subscribe({
            next: (locs) => {
              resolve(locs);
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      }

      this.logger.debug('Got all locations from cache', this.cacheProperties, this.locations);
      if (this.locations) {
        return this.locations;
      }

      this.logger.error('Wanted to return locations from cache, but they were empty', this.locations);
    }

    this.locationsSub$ = new Subject<ListResponse<Location>>();

    return this.appsyncService.query<ListResponse<Location>>(listLocations, {
      start,
      limit,
      search,
      orderBy,
      filterDealershipId,
    })
      .then(async (locations) => {
        if (noParams) {
          this.locations = locations;
          this.cacheProperties = { userSub: await this.userService.getCurrentSub() ?? '', date: new Date() };

          if (this.locationsSub$) {
            this.locationsSub$.next(this.locations);
            this.locationsSub$ = undefined;
          }
        }

        this.logger.debug('Fetched all locations', locations);

        return locations;
      });
  }

  public async getOrgaentities(): Promise<IOrgaentity[]> {
    return this.appsyncService.query<ListResponse<IOrgaentity>>(listOrgaentities)
      .then((oes) => {
        this.logger.debug('Fetched OEs', oes);
        return oes.items;
      });
  }
}
