<div tabindex="-1" role="dialog">
  <form id="newContactForm" (ngSubmit)="saveForm()" #newContactForm="ngForm">
    <h2 class="mb-4">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CREATE_CONTACT' | translate }}</h2>
    <div>
      <h5 class="mb-3">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CONTACT_DATA' | translate }}</h5>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME' | translate }}*</p>
        <input
          type="text"
          name="name"
          class="form-control form-control-sm"
          [(ngModel)]="model.name"
          #name="ngModel"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME_PLACEHOLDER' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="name.invalid && name.dirty && name.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME2' | translate }}</p>
        <input
          type="text"
          name="name2"
          class="form-control form-control-sm"
          [(ngModel)]="model.name2"
          #name2="ngModel"
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.NAME2_PLACEHOLDER' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="name2.invalid && name2.dirty && name2.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.STREET' | translate }}*</p>
        <input
          type="text"
          name="street"
          class="form-control form-control-sm"
          [(ngModel)]="model.street"
          #street="ngModel"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.STREET_PLACEHOLDER' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="street.invalid && street.dirty && street.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CITY' | translate }}*</p>
        <input
          type="text"
          name="city"
          class="form-control form-control-sm"
          [(ngModel)]="model.city"
          #city="ngModel"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CITY' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="city.invalid && city.dirty && city.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.ZIP' | translate }}*</p>
        <input
          type="text"
          name="zip"
          class="form-control form-control-sm"
          [(ngModel)]="model.zip"
          #zip="ngModel"
          maxlength="10"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.ZIP' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="zip.invalid && zip.dirty && zip.touched"
        />
      </div>
      <div class="form-group">
        <p class="mt-2">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.COUNTRY' | translate }}*</p>
        <ng-select
          name="countries"
          [(ngModel)]="selectedCountry"
          [items]="selectCountries"
          (change)="selectedCountryChanged()"
          [disabled]="formDisabled"
          [clearable]="false"
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.COUNTRY' | translate }}"
        ></ng-select>
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.GSSN' | translate }}*</p>
        <input
          type="text"
          name="gssn"
          class="form-control form-control-sm"
          [(ngModel)]="model.gssn"
          #gssn="ngModel"
          required
          pattern="[0-9]{7,8}"
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.GSSN' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="gssn.invalid && gssn.dirty && gssn.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.VATID' | translate }}*</p>
        <input
          type="text"
          name="vatId"
          class="form-control form-control-sm"
          [(ngModel)]="model.vatId"
          #vatId="ngModel"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.VATID' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="vatId.invalid && vatId.dirty && vatId.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.MAIL' | translate }}*</p>
        <input
          type="email"
          name="mail"
          class="form-control form-control-sm"
          [(ngModel)]="model.mail"
          #mail="ngModel"
          pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+$"
          required
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.MAIL_PLACEHOLDER' | translate }}"
          autocomplete="email"
          [disabled]="formDisabled"
          [class.form-field-error]="mail.invalid && mail.dirty && mail.touched"
        />
      </div>
      <div class="form-group">
        <p>{{ 'ADMIN_PAGES.CONTACTS.DETAILS.PHONE' | translate }}</p>
        <input
          type="text"
          name="phone"
          class="form-control form-control-sm"
          [(ngModel)]="model.phone"
          #phone="ngModel"
          placeholder="{{ 'ADMIN_PAGES.CONTACTS.DETAILS.PHONE' | translate }}"
          [disabled]="formDisabled"
          [class.form-field-error]="phone.invalid && phone.dirty && phone.touched"
        />
      </div>
    </div>
    <hr style="margin: 44px -44px">
    <div class="text-right">
      <button type="button" (click)="closePopup()" [disabled]="formDisabled" class="btn btn-secondary">{{ 'ADMIN_PAGES.CONTACTS.DETAILS.CLOSE_OVERLAY' | translate }}</button>
      <button type="submit" form="newContactForm" [disabled]="!newContactForm.form.valid || formDisabled || model.country === '-'" class="btn btn-primary ml-2">+ {{ 'ADMIN_PAGES.CONTACTS.DETAILS.CREATE_CONTACT' | translate }}</button>
    </div>
  </form>
</div>
