export abstract class ModelAbstract {
  public id: number;
  public created: Date;
  public updated: Date;

  constructor(id: number, created: Date, updated: Date) {
    this.id = id;
    this.created = created;
    this.updated = updated;
  }
}
