import { Injectable } from '@angular/core';
import {
  IOrgaentity,
  IOrgaentityCompany,
  IOrgaentityLocation,
  IOrgaentityClient,
  IUserRoleList,
} from '@dep/common/interfaces';
import { RoleRight } from '@dep/common/shop-api/enums/role-right.enum';
import { ShopLevel } from '@dep/common/shop-api/enums/shop.enum';
import { NGXLogger } from 'ngx-logger';

import { UserService } from '@dep/frontend/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ShopUserService {
  constructor(
    private userService: UserService,
    private logger: NGXLogger,
  ) { }

  public async getUserId(): Promise<number | null> {
    const idToken = await this.userService.getIdToken();
    if (idToken) {
      this.logger.log('ShopUserService: Got user id from idToken', idToken.payload['custom:id']);
      return +idToken.payload['custom:id'];
    }

    this.logger.error('ShopUserService: Error while getting user id from idToken');
    return null;
  }

  /**
   * @deprecated Use `getOrgaentityIds()` instead which supports getting multiple IDs.
   */
  public async getOrgaentityId(): Promise<number | null> {
    const roles = await this.userService.getRoles();
    if (roles && roles.items) {
      const foundRole: IUserRoleList | undefined = roles.items
        .find((item) => item.role.name !== 'Administrator' && item.role.rights.includes(RoleRight.ShopRead));

      this.logger.debug('ShopUserService: getOrgaentityId: Found role', foundRole);
      return foundRole && foundRole.orgaentities[0] ? foundRole.orgaentities[0].id : null;
    }

    this.logger.error('ShopUserService: Error while getting orgaentity ID');
    return null;
  }

  /**
   * Get all Orgaentity IDs with SHOP_READ right.
   *
   * @returns Orgaentitiy IDs
   */
  public async getOrgaentityIds(): Promise<number[]> {
    const roles = await this.userService.getRoles();
    const rolesWithShopRead: IUserRoleList[] | undefined = roles.items
      .filter((item) => item.role.name !== 'Administrator' && item.role.rights.includes(RoleRight.ShopRead));
    this.logger.debug('ShopUserService: getOrgaentityIds: Found roles with SHOP_READ', rolesWithShopRead);

    const orgaentitiesWithShopRead = rolesWithShopRead.flatMap((role) => role.orgaentities.map((oe) => oe.id));
    this.logger.debug('ShopUserService: getOrgaentityIds: Found OEs with SHOP_READ', orgaentitiesWithShopRead);

    return orgaentitiesWithShopRead;
  }

  /**
   * Get path of the orgaentity of the first role with SHOP_READ right that is not
   * an Administrator.
   *
   * @returns Path
   */
  public async getFirstShopOrgaentity(): Promise<IOrgaentity | IOrgaentityCompany | IOrgaentityLocation | IOrgaentityClient | null> {
    const roles = await this.userService.getRoles();
    const foundRole: IUserRoleList | undefined = roles.items
      .find((item) => item.role.name !== 'Administrator' && item.role.rights.includes(RoleRight.ShopRead));

    this.logger.log('ShopUserService: Found role', foundRole);
    return foundRole && foundRole.orgaentities[0] ? foundRole.orgaentities[0] : null;
  }

  public async getShopLevel(): Promise<ShopLevel | undefined> {
    // Check rights for shop level.
    if (await this.userService.hasRight(RoleRight.ShopProductCreate)) {
      return ShopLevel.VS1;
    }
    if (await this.userService.hasRight(RoleRight.ShopProductFromSharedCreate)) {
      return ShopLevel.VS2;
    }
    if (await this.userService.hasRight(RoleRight.ShopRead)) {
      return ShopLevel.VS3;
    }

    return undefined;
  }
}
